<template>
  <div class="aboutmain">
    <div class="about">
        <h2>About Us</h2>
        <p>Our experienced team has over 20 years combined experience working in industry for banks, the Financial Conduct Authority or the Financial Ombudsman Service. </p>
        <p>We offer full transparency during the entire process and invite you to look up our registration information or to come meet us in person. The service is very straight forward and delivers results.</p>
        <p>There are tens of millions of victims every single year throughout the world. We know how to recover your funds and we helped thousands of scam victims from around the world to recover their funds.</p>
        <br>
        <div class="cofounders"
        v-if="!$vuetify.display.smAndDown"        
        >
          <ul>
            <li>
              <div class="pics">
                <v-img 
                  class="bg-white"
                  width="200"
                  :aspect-ratio="1"
                  src='../../assets/founder2.png'
                  cover
                  style="border-radius: 50%;"
                ></v-img>              </div>
              <div class="name"><h3>Williams Ayles</h3></div>
              <div class="position"><p>Co-Founder</p></div>
            </li>
            <li>
              <div class="pics">
                <v-img
                  class="bg-white"
                  width="200"
                  :aspect-ratio="1"
                  src='../../assets/founder.png'
                  cover
                  style="border-radius: 50%;"
                ></v-img>
              </div>
              <div class="name"><h3>Stuart McFadden</h3></div>
              <div class="position"><p>Chief Operating Officer</p></div>
            </li>
            <li>
              <div class="pics">
                <v-img
                  class="bg-white"
                  width="200"
                  :aspect-ratio="1"
                  src='../../assets/founder3.png'
                  cover
                  style="border-radius: 50%;"
                ></v-img>
              </div>
              <div class="name"><h3>Jeremy Goffage</h3></div>
              <div class="position"><p>Cheif investigator</p></div>
            </li>
          </ul>
        </div>
        <div 
            class="small"
            v-if="$vuetify.display.smAndDown"        
        >
            <v-carousel hide-delimiters style="height: 100%;">
                <v-carousel-item v-for="(item,i) in items" :key="i" cover>
                <div style="padding: 20px; width: 80%; margin: 0px auto">
                    <img :src="item.src" style="width: 100%; border-radius: 50%;" />
                    <h3>{{item.name}}</h3>
                    <!-- <p>Having seen how many banks were failing to support victims of scams, he set up Refundee in 2021 to help victims when they need it most.</p> -->
                </div>

                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HeroAbout',

    data() {
      return {
        items: [
          {
            src: require('../../assets/founder2.png'),
            name: 'Williams Ayles',
          },
          {
            src: require('../../assets/founder.png'),
            name: 'Stuart McFadden',
          },
          {
            src: require('../../assets/founder3.png'),
            name: 'Jeremy Goffage',
          },
        ],
      }
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/HeroAbout.scss";
</style>