<template>
  <footer>
    <div class="footer-contain">
      <div class="cont">
        <h4>Contact Us</h4>
        <ul>
          <li><a href="tel:+447752532144">+447752532144</a></li>
        </ul>
      </div>
      <div class="loct"> 
        <h4>Location</h4>
        <p>38, Breach Barns Lane</p>
        <p>Waltham Abbey</p>
        <p>Epping Forest</p>
        <p>Essex, United Kingdom</p>
        <p>EN9 2AQ</p>
      </div>
      <div class="bank">
        <h4>Banks</h4>
        <ul>
          <li>Banks Transfers</li>
          <li>Barclays</li>
          <li>Revoult</li>
          <li>HSBC</li>
        </ul>
      </div>
      <!-- <div class="soci">
        <h4>Social</h4>
        <ul>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Youtube</li>
        </ul>
      </div> -->
      <div class="off-hrs">
        <h4>Our Office Hours</h4>
        <ul>
          <li>Mon-Fri: 8:00-19:00 GMT</li>
          <li>Sun: 8:00-14:00 GMT</li>
          <li>Sat: Closed</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style lang="scss" scoped>
@import "../scss/Footer.scss";
</style>