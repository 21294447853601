<template>
  <div class="mainwhy">
    <div class="why">
        <div class="tabs">
            <h2>Why Work With Us?</h2>
            <p>The short answer: We only take on cases we believe can result in a substantial recovery. Your time is as valuable as ours, and that’s why we are completely transparent in our evaluation of every case. If it can’t be done, we will tell you so. But, if we feel there is a chance for full or partial recovery, then we'll get to work right away.</p>
        </div>
        <div class="tabs">
            <h2>Your money back is guaranteed</h2>
            <p>The fund recovery process can be a lengthy one and requires perseverance. Therefore it is vital that our clients are ready for it and trust us every step of the way. So if for any reason you are doubtful, you can ask for a full refund within the first 14 business days of the process.*</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Why',
}
</script>

<style lang="scss" scoped>
@import "../../scss/Why.scss";
</style>