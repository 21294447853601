<template>
    <div class="investment">
        <!-- <img src="../../assets/icon-hero.webp" alt=""> -->
        <div class="background-image-investment">
            <v-img
                class="investment-bg"
                width="200"
                :aspect-ratio="1"
                src='../../assets/icon-hero.webp'
                cover
                style="width: 100%; object-fit: contain;"
            ></v-img>
        </div>

        <div class="texts">
            <div class="text-content">
                <h1>Invest your money with us today</h1>
                <p>As crypto grows, we believe everyone should have a simple and familiar way to access it — a way that’s expertly designed, clearly explained, and professionally managed. We make crypto easy.</p>
                <v-btn href="/invest">Start Investing</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Investment"
}
</script>

<style lang="scss" scoped>
@import "../../scss/Investment.scss";
</style>